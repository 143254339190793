<template>
    <div class="rating-criteria-popup_container" :class="{ hide: !visible }">
        <div class="rating-criteria-popup">
            <div class="rating-criteria-popup_image_container">
                <img :src="image" :alt="title" width="396" height="223" />
            </div>

            <div class="rating-criteria-popup_title_container">
                <div class="rating-criteria-popup_title">
                    {{ title }}
                </div>
                <ul
                    v-for="contentItem in content"
                    v-if="hasContentList"
                    :key="contentItem"
                    class="list-disc"
                >
                    <li class="rating-criteria-popup_content ml-4">
                        {{ contentItem }}
                    </li>
                </ul>
                <div v-else class="rating-criteria-popup_content">
                    {{ content[0] }}
                </div>
            </div>

            <div class="rating-criteria-popup_action" @click="close">
                <slot name="send">{{ $t('ok') }}</slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "RatingCriteriaPopup",
  props: {
    title: {
      type: String,
      required: true
    },
    image: {
      type: String,
      required: true
    },
    content: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      visible: false
    };
  },
  computed: {
    hasContentList() {
      return this.content?.length > 1;
    }
  },
  methods: {
    show() {
      this.visible = true;
      document.querySelector("body").classList.add(
        "overflow-hidden"
      );
    },
    hide() {
      this.visible = false;
      document.querySelector("body").classList.remove(
        "overflow-hidden"
      );
    },
    close() {
      this.hide();
    }
  }
};
</script>

<style scoped>
.rating-criteria-popup {
  @apply relative max-w-3xl max-h-screen overflow-auto mx-4 bg-white border border-gray-50 rounded-2xl shadow-md p-4;
  width: 428px;
}
.rating-criteria-popup_container {
  @apply fixed flex items-center justify-center inset-0 bg-gray-600 bg-opacity-20 z-30 gap-4 px-4;
}
.rating-criteria-popup_container.hide {
  @apply hidden;
}
.rating-criteria-popup_title {
  @apply font-bold leading-5 text-gray-l-900;
  font-size: 22px;
}
.rating-criteria-popup_title_container {
  @apply flex flex-col gap-2 py-4;
}
.rating-criteria-popup_content {
  @apply font-normal text-fifteen-exploitation leading-5 text-gray-l-700;
}
.rating-criteria-popup_action {
  @apply flex justify-center w-full font-semibold text-fifteen-exploitation leading-4.5 border border-gray-l-800 rounded-lg py-2 cursor-pointer;
}
</style>
